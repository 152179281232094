<template>
    <div>
        <div class="toolBar mBottom10">
            <el-button type="primary" size='small' plain @click="isShowSelDlg = true">查询</el-button>
            <el-button type="primary" size='small' plain @click="isShowAddDlg = true">新增</el-button>
            <el-button plain size='small' class='fr' @click="refresh">刷新</el-button>
        </div>

        <el-card class="box-card">
            <el-table :data="roleList" size="small" class="maxWidth" stripe>
                <el-table-column type="index" width="50" label='序号' align="left"></el-table-column>
                <el-table-column prop="name" label="角色名称" align="left"></el-table-column>
                <el-table-column prop="keyWord" label="角色关键字" align="left"></el-table-column>
                <el-table-column label="操作" align="center">
                    <template slot-scope="scope">
                        <el-button size="mini" type='text' @click='handleDetail(scope.row)'>分配权限</el-button>
                        <el-button size="mini" type='text' style='margin: 0 20px;' @click="editRole(scope.row)">修改
                        </el-button>
                        <el-button size="mini" type='text' class='hkDanger' @click="delRole(scope.row)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </el-card>

        <div class="toolBar clearfix" style="margin-top: 10px">
            <pagi-nation :page='rolePage' @sizeChange='roleSizeChange' @currentChange='roleCurrentChange'></pagi-nation>
        </div>

        <!-- 查询角色 -->
        <el-dialog title="查询" v-dialogDrag :visible.sync="isShowSelDlg" width="40%">
            <el-form :model="selRoleData" :rules="rules" size='small' label-width="100px">
                <el-row>
                    <el-col :sm="24">
                        <el-form-item label="角色名称">
                            <el-input v-model="selRoleData.name" clearable></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :sm="24">
                        <el-form-item label="角色关键字">
                            <el-input v-model="selRoleData.keyWord" clearable></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <span slot="footer" class="dialog-footer">
					<el-button @click="isShowSelDlg = false" size='small'>取消</el-button>
					<el-button type="primary" @click="searchRole" size='small'>确定</el-button>
				  </span>
        </el-dialog>

        <!-- 增加角色 -->
        <el-dialog title="增加角色" v-dialogDrag :visible.sync="isShowAddDlg" width="40%">
            <el-form ref="roleForm" :model="addRoleData" :rules="rules" size='small' label-width="100px">
                <el-row>
                    <el-col :sm="24">
                        <el-form-item label="角色名称" prop="name">
                            <el-input v-model="addRoleData.name" placeholder="角色名称" clearable></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :sm="24">
                        <el-form-item label="角色关键字" prop="keyWord">
                            <el-input v-model="addRoleData.keyWord" placeholder="不可重复" clearable></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <span slot="footer" class="dialog-footer">
					<el-button @click="closeAddDlg" size='small'>取消</el-button>
					<el-button type="primary" @click="addRole" size='small'>确定</el-button>
				  </span>
        </el-dialog>

        <!-- 修改角色 -->
        <el-dialog title="修改角色" v-dialogDrag :visible.sync="isShowEditDlg" width="40%">
            <el-form ref="roleForm2" :model="editRoleData" :rules="rules" size='small' label-width="100px">
                <el-row>
                    <el-col :sm="24">
                        <el-form-item label="角色名称" prop="name">
                            <el-input v-model="editRoleData.name" placeholder="角色名称" clearable></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :sm="24">
                        <el-form-item label="角色关键字" prop="keyWord">
                            <el-input v-model="editRoleData.keyWord" placeholder="角色关键字" clearable></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <span slot="footer" class="dialog-footer">
						<el-button @click="isShowEditDlg = false" size='small'>取消</el-button>
						<el-button type="primary" @click="updateRole()" size='small'>确定</el-button>
					  </span>
        </el-dialog>

    </div>
</template>

<script>
    class Role {
        constructor() {
            this.name = null;
            this.keyWord = null;
        }

        reset() {
            this.constructor();
        }
    }

    export default {
        name: 'permissionConfig',
        data() {
            return {
                roleList: [],
                tableHeight: document.documentElement.clientHeight - 288,
                rolePage: new this.Page(),

                isShowAddDlg: false,
                isShowEditDlg: false,
                isShowSelDlg: false,

                //查询
                selRoleData: new Role(),
                //新增
                addRoleData: new Role(),
                //修改
                editRoleData: new Role(),

                rules: {
                    name: [{required: true, message: '角色名称是必须的', trigger: 'blur'}],
                    keyWord: [{required: true, message: '关键字是必须的，且不可重复', trigger: 'blur'}],
                },

                //权限相关数据
                permissionData: null,
                permissionProps: {children: 'children', label: 'name', id: "id"},
            }
        },
        computed: {
        },
        methods: {
            handleDetail(row) {
                this.$router.push({name: 'roleDetail', query: {id: row.id,name:row.name}});
            },
            roleSizeChange(val) {
                this.rolePage.currentPage = 1;
                this.rolePage.pageSize = val;
                this.getRoleList();
            },
            roleCurrentChange(val) {
                this.rolePage.currentPage = val;
                this.getRoleList();
            },
            searchRole() {
                this.rolePage.reset();
                this.getRoleList();
                this.isShowSelDlg = false;
            },
            async getRoleList() {
                let param = {
                    keyWord: this.selRoleData.keyWord,
                    name: this.selRoleData.name,
                    limit: this.rolePage.pageSize,
                    offset: (this.rolePage.currentPage - 1) * this.rolePage.pageSize
                };
                let res = await this.$http.post('/sys/roleList', param);
                if (res.state) {
                    for (let item of res.data.list) {
                        item.createTime = this.getDateTime(item.createTime);
                    }
                    this.roleList = res.data.list;
                    this.rolePage.total = res.data.totalCount;
                }
            },
            async addRoleRequest() {
                let res = await this.$http.post('/sys/addRole', this.addRoleData);
                if (res.state) {
                    this.$message.success('操作成功');
                    this.isShowAddDlg = false;
                    this.addRoleData.reset();
                    this.getRoleList();
                }
            },
            addRole() {
                this.$refs['roleForm'].validate((valid) => {
                    if (valid) {
                        this.addRoleRequest();
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            closeAddDlg() {
                this.addRoleData.reset();
                this.isShowAddDlg = false;
            },
            async updateRoleRequest() {
                let res = await this.$http.post('/sys/updRole', this.editRoleData);
                if (res.state) {
                    this.$message.success('操作成功');
                    this.isShowEditDlg = false;
                    this.getRoleList();
                }
            },
            updateRole() {
                this.$refs['roleForm2'].validate((valid) => {
                    if (valid) {
                        this.updateRoleRequest();
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            editRole(data) {
                Object.assign(this.editRoleData, data);
                this.isShowEditDlg = true;
            },
            async delRoleRequest(id) {
                let res = await this.$http.post('/sys/delRole', {id});
                if (res.state) {
                    this.$message.success('操作成功');
                    this.getRoleList();
                }
            },
            delRole(row) {
                this.$confirm('此操作将永久删除该角色, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.delRoleRequest(row.id);
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                });
            },
            refresh() {
                this.rolePage.reset();
                this.getRoleList();
            }
        },
        created() {
            this.getRoleList();
        },
        mounted() {
            window.onresize = () => {
                window.fullHeight = document.documentElement.clientHeight
                this.tableHeight = window.fullHeight - 283;
            }
        }
    }

</script>

<style>
</style>